import React from "react"

import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

export default function CategoryPage({ data }) {
  return  (
      <Layout>
      <Helmet>
        <title>カテゴリ: {data.markdownRemark.frontmatter.categoryname} | わく☆すたブログ </title>
      </Helmet>
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="archive_title_wrap">
                <h1 className="archive_title">カテゴリ: {data.markdownRemark.frontmatter.categoryname}</h1>
              </div>
              <div className="keni-section_wrap keni-section_wrap_style02">
                <div className="keni-section">
                  <div className="entry-list entry-list_style01">
                    {data.allMarkdownRemark.edges.map(({ node }) => (
                        <article class="entry-list_item">
                          <div className="entry">
                            <figure className="entry_thumb">
                              <img src={ node.frontmatter.thumbnail || `/images/wakusuta_sky.jpg` } alt="" />
                            </figure>
                            <div className="entry_inner">
                                  <h2 className="entry_title"><a href={node.fields.slug}>{node.frontmatter.title}</a></h2>
                                  <div className="news-list_item_date">
                                    <time datetime={node.frontmatter.date} itemprop="datePublished" content={node.frontmatter.date}>
                                      {node.frontmatter.dateJP}
                                    </time>
                                  </div>
                                  <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                  <div className="ently_read-more">
                                    <a href={node.fields.slug}  class="btn dir-arw_r"><span class="icon_arrow_s_right"></span>続きを読む</a>
                                  </div>
                            </div>
                          </div>
                        </article>                 
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      </Layout>
    )
}

export const query = graphql`
    query CategoryQuery($skip: Int!, $limit: Int!, $slug: String!, $tag: String!) {
        allMarkdownRemark(
            filter: {frontmatter: {pagetype: {eq: "blog"}, category: { eq: $tag }}}
            sort: {fields: frontmatter___date, order:DESC}
            skip: $skip
            limit: $limit
          ) {
          edges {
            node {
              frontmatter {
                title
                dateJP:date(formatString: "YYYY年MM月DD日")
                date(formatString: "YYYY-MM-DD")
                thumbnail
              }
              fields {
                slug
              }
              excerpt(format: MARKDOWN)
            }
          }
        }
        markdownRemark(
          frontmatter: {categoryslug: { eq: $slug }}
        ) {
          frontmatter {
            categoryname
          }
        }
    }
`